<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { Sm400Black, Sm400Gray } from '~/components/typography'
import BucketListAvatar from '~/components/ui/Avatars/BucketListAvatar.vue'
import BtnGhost from '~/components/ui/Buttons/ghost.vue'
import VText from '~/components/ui/VText.vue'

import { useBucketSelection } from '~/composables/useBucketSelection'

import { useInfluencersStore } from '~/stores/influencers'
import { useMiscResizeStore } from '~/stores/miscResize'

import { grooverBucketIsEqualToIdentification } from '~/helpers/favorites/grooverBucket'

import { debounce } from '~/utils'

import { Breakpoints } from '~/enums/breakpoints'

import type GrooverBucket from '~/helpers/favorites/grooverBucket'
import type Bucket from '~/types/bucket'
import type { AvailableBucketInputs } from '~/types/bucketNavigatorEmits'
import type { StatsV3Influencer } from '~/types/influencer'

type Props = {
  bucket: Bucket | GrooverBucket
  influencerIdsToBookmark?: number[]
  navigating?: boolean
  bucketSelectionData?: AvailableBucketInputs
}

type Emits = {
  bucketToggled: [{ bucketId: number; wasInTheBucket: boolean }]
}

const props = withDefaults(defineProps<Props>(), {
  influencerIdsToBookmark: () => [],
  navigating: false,
  bucketSelectionData: () => ({
    bucketType: 'classic',
    identificationValue: 0,
  }),
})

const emit = defineEmits<Emits>()

const { t } = useI18n()
const {
  bucketIsRecommended,
  emittedBucketIsClassic,
  emittedBucketIsRecommended,
  bucketIsClassic,
  generateAnyBucketName,
  isMadeByGroover,
} = useBucketSelection()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
const { GET_BY_ID: GET_INFLUENCER_BY_ID } = useInfluencersStore()

const bucketName = computed(() => generateAnyBucketName(props.bucket))

const titleConfig = computed(() =>
  SCREEN_WIDTH.value >= Breakpoints.sm ? 'sans/20/bold' : 'sans/16/medium',
)

const influencerIds = computed(() => {
  if (bucketIsClassic(props.bucket)) return props.bucket.influencers
  else return props.bucket.influencerIds
})

const infIsInBucket = computed(() =>
  props.influencerIdsToBookmark.every((influencerId) =>
    influencerIds.value.includes(influencerId),
  ),
)

const influencers = computed(() =>
  influencerIds.value.reduce((accumulator, influencerId) => {
    const influencer = GET_INFLUENCER_BY_ID(influencerId)

    if (influencer) accumulator.push(influencer)

    return accumulator
  }, [] as StatsV3Influencer[]),
)

const listSubtitle = computed(() => {
  if (SCREEN_WIDTH.value < Breakpoints.lg) {
    return `${influencers.value.length} ${t(
      'common.multi_inf',
      influencers.value.length,
    )}`
  } else {
    return t('common.multi_pro', influencers.value.length)
  }
})

const selected = computed(() => {
  if (
    bucketIsClassic(props.bucket) &&
    emittedBucketIsClassic(props.bucketSelectionData)
  ) {
    return props.bucket.id === props.bucketSelectionData.identificationValue
  } else if (
    bucketIsRecommended(props.bucket) &&
    emittedBucketIsRecommended(props.bucketSelectionData)
  ) {
    return grooverBucketIsEqualToIdentification(
      props.bucket,
      props.bucketSelectionData.identificationValue,
    )
  } else {
    return false
  }
})

const debouncedToggle = debounce(function () {
  toggle()
}, 200)

function toggle() {
  if (bucketIsClassic(props.bucket)) {
    emit('bucketToggled', {
      bucketId: props.bucket.id,
      wasInTheBucket: infIsInBucket.value,
    })
  } else {
    console.warn(
      'Tried to select a GrooverBucket, this feature is not need yet',
    )
  }
}
</script>

<template>
  <div
    :class="{ navigating, selected }"
    class="elemWrapper tw-layout-gap tw-py-layout"
    @click="debouncedToggle"
  >
    <div class="btnWrap tw-shrink-0">
      <BucketListAvatar :bucket="bucket" use-bigger-logo />
    </div>
    <div class="tw-grid tw-flex-grow tw-grid-cols-1 tw-gap-sm">
      <VText
        :cfg="titleConfig"
        color="black"
        class="bucketTitle ellipsis tw-capitalize-first"
        :class="{ 'tw-capitalize': !bucketIsClassic(bucket) }"
      >
        {{ bucketName }}
      </VText>
      <div>
        <Sm400Black>{{ listSubtitle }} </Sm400Black>
        <Sm400Gray v-if="isMadeByGroover(bucket)"
          >• {{ $t('common.byGroover') }}</Sm400Gray
        >
      </div>
    </div>
    <div v-if="!navigating">
      <BtnGhost @click="debouncedToggle">
        <i
          :class="{
            'fa-bookmark': true,
            'fas tw-bg-gradient-135 tw-bg-grooverGradient tw-bg-clip-text tw-text-transparent':
              infIsInBucket,
            'far toBookmark': !infIsInBucket,
          }"
        />
        <span class="desktopOnly">
          {{ infIsInBucket ? $t('common.saved') : $t('common.save') }}
        </span>
      </BtnGhost>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.elemWrapper {
  @apply tw-flex tw-cursor-pointer tw-items-center tw-justify-start;

  @screen lg {
    @apply tw-px-4 tw-transition-colors tw-duration-150 tw-ease-in-out;

    &.selected {
      @apply tw-bg-gray-300;
    }
  }
}

.elemWrapper:not(.navigating) {
  .overlay {
    transition: all 0.3s ease-in-out;
    &.hasCurrentInfluencer {
      @apply tw-opacity-100;
      background: linear-gradient(-45deg, rgb(255, 200, 87), rgb(235, 69, 124));
    }
  }

  &:hover {
    @apply tw-bg-gray-100;

    div.overlay {
      @apply tw-bg-black tw-opacity-100;
    }
    .btn.ghost {
      @apply tw-border-orange-100 tw-bg-orange-100 tw-shadow-round;
    }
  }
}

.btnWrap {
  @apply tw-flex tw-items-center tw-justify-center tw-overflow-hidden tw-rounded-full tw-bg-gray-200;

  width: 56px;

  .innerRatioContainer {
    @apply tw-relative tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center;
  }
}

.overlay {
  @apply tw-absolute tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-5 tw-opacity-0 tw-transition-opacity tw-duration-150 tw-ease-in-out;
}

.btn.ghost .btnText > i.toBookmark {
  @apply tw-font-normal;
}

.btn.ghost .desktopOnly {
  display: unset;
  margin-left: 8px;
}

@media screen and (max-width: 479px) {
  .btn.ghost .desktopOnly {
    @apply tw-hidden;
  }
}
</style>

<style lang="scss">
// TODO use v-deep selector instead of loose un-scoped style block
.elemWrapper .btn.ghost .btnText {
  @apply tw-whitespace-nowrap;
}
</style>
